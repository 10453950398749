import React from "react"
import * as Chakra from "@chakra-ui/react"
import { PageProps } from "gatsby"
import { Seo } from "../../components/seo"
import StudioIdeasGrid from "../../components/studio-ideas-grid"

const StudioIdeas = ({ location }: PageProps): React.ReactElement => (
  <Chakra.Box width="100%">
    <Seo title="Ideas" location={location} />
    <StudioIdeasGrid />
  </Chakra.Box>
)

export default StudioIdeas
