import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import formatDate from "../utils/format-date"
import { fastReduce } from "../utils/fast-reduce"
import FadeTransition from "./transitions/ideas-grid-transition"
import { useScrollOpacity } from "../utils/use-scroll-opacity"
import {ResponsiveVideoImage} from "./video-image";

const StudioIdeasGrid = React.forwardRef((_props, ref) => {
  const blogData = useStaticQuery(graphql`
    query {
      site {
        buildTime
      }
      allWpPost(sort: {fields: date, order: DESC}) {
        nodes {
          title
          categories {
            nodes {
              name
              id
            }
          }
          date
          link
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
          postContent {
            video
            hoverImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const [posts] = React.useState(blogData.allWpPost.nodes)
  const [categories, setFilteredCategories] = React.useState(posts)
  const [active, setActive] = React.useState("all")

  const handleFilter = (e) => {
    const categoryName = e.target.value
    if (e.target.value === "all") {
      setFilteredCategories(posts)
      setActive("all")
    } else {
      setFilteredCategories(
        posts.filter((post) => post.categories.nodes[0].name == categoryName)
      )
      setActive(e.target.value)
    }
  }

  const [childIsHovered, setChildIsHovered] = React.useState(false)

  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
    >
      <Chakra.Box
        width="100%"
        display="flex"
        maxWidth="1800px"
        alignItems="flex-start"
        marginLeft="auto"
        paddingTop="34px"
        marginRight="auto"
        paddingLeft="40px"
        paddingRight="40px"
        flexDirection="column"
        paddingBottom="50px"
        backgroundColor="transparent"
        css={{
          "@media (max-width: 479px)": {
            paddingLeft: "20px",
            paddingRight: "20px",
          },
        }}
      >
        <Chakra.VStack
          width="100%"
          justify="start"
          align="start"
          pb="58px"
          zIndex={1}
          opacity={childIsHovered !== false ? 0.1 : 1}
          transition="0.5s"
        >
          <Chakra.Button
            fontSize="16px"
            lineHeight="24px"
            value="all"
            margin="0px !important"
            onClick={handleFilter}
            bg="transparent"
            minWidth="22px"
            height="27px"
            _hover={{ bg: "transparent", color: "#000" }}
            _focus={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            padding="0px !important"
            py="0px"
            cursor="pointer"
            color={active == "all" ? "black" : "rgba(0,0,0,0.5)"}
            fontWeight="400"
          >
            All
          </Chakra.Button>
          {fastReduce(
            blogData.allWpPost.nodes.map((x) => x.categories.nodes[0]),
            "name"
          ).map((item, index) => (
            <Chakra.Button
              key={index}
              fontSize="16px"
              lineHeight="24px"
              fontWeight="400"
              height="27px"
              py="0px"
              margin="0px !important"
              color={active == item.name ? "black" : "rgba(0,0,0,0.5)"}
              onClick={handleFilter}
              bg="transparent"
              value={item.name}
              _hover={{ bg: "transparent", color: "#000" }}
              _focus={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              padding="0px !important"
            >
              {item.name}
            </Chakra.Button>
          ))}
        </Chakra.VStack>
        <Chakra.Box position="relative">
          {categories.map((post, idx) => (
            <Chakra.Flex
              key={idx}
              alignItems="center"
              justifyContent="center"
              position="fixed"
              left={0}
              right={0}
              top={0}
              bottom={0}
              opacity={childIsHovered === idx ? 1 : 0}
              transition="0.4s"
              transitionProperty="opacity"
              bg="rgba(244, 242, 237, 0.9)"
              zIndex={childIsHovered !== false ? 10 : 0}
            >
              <Chakra.Box
                  width="100%"
                  height="100%"
                  maxWidth="1160px"
                  maxHeight="400px"
              >
                <ResponsiveVideoImage
                    video_url={post?.postContent?.video}
                    image={post?.postContent?.hoverImage}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    objectFit="contain"
                    objectPosition="center center"
                />
              </Chakra.Box>
            </Chakra.Flex>
          ))}
          <FadeTransition shouldChange={active}>
            <Chakra.Box
              position="relative"
              width="100%"
              display="grid"
              gridGap="30px"
              onMouseLeave={() => setChildIsHovered(false)}
              gridTemplateColumns="repeat(2,1fr)"
              css={{
                "@media (max-width: 991px)": {
                  gridTemplateColumns: "repeat(1,1fr)",
                },
                "@media (max-width: 767px)": {
                  gridTemplateColumns: "repeat(1,1fr)",
                },
                "@media (max-width: 479px)": {
                  gridTemplateColumns: "repeat(1,1fr)",
                },
              }}
            >
              {categories.map((props, idx) => (
                <BlogItem
                  childIsHovered={childIsHovered}
                  {...props}
                  key={idx}
                  index={idx}
                  setChildIsHovered={setChildIsHovered}
                />
              ))}
            </Chakra.Box>
          </FadeTransition>
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

StudioIdeasGrid.displayName = "StudioIdeasGrid"

const BlogItem = ({
  link,
  categories,
  date,
  title,
  setChildIsHovered,
  childIsHovered,
  index,
}) => {
  const [isHovered, setIsHovered] = React.useState(false)

  const scrollOp = useScrollOpacity(index * 100)

  return (
    <Chakra.Box
      opacity={!isHovered && childIsHovered !== false ? "0.1" : "1"}
      width="100%"
      transition="0.3s"
    >
      <Chakra.Box
        width="100%"
        as={Link}
        to={link}
        {...scrollOp}
        transition="0.3s"
        onMouseOver={() => {
          setIsHovered(true)
          setChildIsHovered(index)
        }}
        onMouseOut={() => {
          setIsHovered(false)
          setChildIsHovered(false)
        }}
        _hover={{
          ".child-text": {
            color: "#EA1D25",
          },
          ".child-animated-text": {
            color: "#EA1D25",
          },
        }}
      >
        <Chakra.Box
          width="100%"
          height="100%"
          borderTop="1px solid rgba(0, 0, 0, 0.5)"
          paddingTop="30px"
        >
          <Chakra.Text
            color="rgba(0, 0, 0, 0.5)"
            fontSize="16px"
            fontStyle="normal"
            fontFamily="Beausite 400"
            fontWeight="400"
            lineHeight="19.2px"
            paddingBottom="16px"
            className="child-text"
            transition="0.5s"
          >
            {categories.nodes[0].name} – {formatDate(new Date(date))}
          </Chakra.Text>
          <Chakra.Box
            position="relative"
            zIndex={childIsHovered === index ? 10 : 0}
            className="child-text"
            transition={isHovered ? "0.7s" : "0.3s"}
            transitionProperty="opacity"
          >
            <Chakra.Text
              fontSize="64px"
              maxWidth="100%"
              fontFamily="Beausite 400"
              lineHeight="64px"
              textTransform="uppercase"
              className="child-animated-text"
              css={{
                "@media (max-width: 479px)": {
                  fontSize: "48px",
                  lineHeight: "48px",
                },
                transition: "0.6s",
              }}
            >
              {title}
            </Chakra.Text>
          </Chakra.Box>
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
}

export default StudioIdeasGrid
